import i18next from 'i18next';
import enCommon from './public/locales/en/common.json';
import enHeader from './public/locales/en/header.json';
import enFooter from './public/locales/en/footer.json';
import enSettings from './public/locales/en/settings.json';
import enAuthentication from './public/locales/en/authentication.json';
import enOnboarding from './public/locales/en/onboarding.json';

export const debug = false;
export const defaultNS = 'common';
export const fallbackLng = 'en';
export const fallbackNS = 'common';

i18next.init({
    debug,
    fallbackLng,
    defaultNS,
    fallbackNS,
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            common: enCommon,
            header: enHeader,
            settings: enSettings,
            footer: enFooter,
            authentication: enAuthentication,
            onboarding: enOnboarding
        }
    }
});

export default i18next;
